import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ policy on password protection.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Non-compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Related Documents</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Definitions and Terms</AnchorLink>
    </AnchorLinks>
    <h2>{`Overview`}</h2>
    <p>{`Passwords are an important aspect of computer security. A poorly chosen
password may result in unauthorized access and/or exploitation of our
resources. All staff, including contractors and vendors with access to
Data Migrators systems, are responsible for taking the appropriate
steps, as outlined below, to select and secure their passwords.`}</p>
    <h2>{`Purpose`}</h2>
    <p>{`The purpose of this policy is to establish a standard for creation of
strong passwords and the protection of those passwords.`}</p>
    <h2>{`Scope`}</h2>
    <p>{`The scope of this policy includes all personnel who have or are
responsible for an account (or any form of access that supports or
requires a password) on any system that resides at any Data Migrators
facility, has access to the Data Migrators network, or stores any
non-public Data Migrators information.`}</p>
    <h2>{`Policy`}</h2>
    <h3>{`Password Creation`}</h3>
    <ol>
      <li parentName="ol">{`All user-level and system-level passwords must conform to the `}<em parentName="li">{`Password Construction Guidelines`}</em>{`.`}</li>
    </ol>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">
        <p parentName="li">{`Users must use a separate, unique password for each of their work related accounts. Users may not use any work related passwords for their own, personal accounts.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`User accounts that have system-level privileges granted through group memberships or programs such as sudo must have a unique password from all other accounts held by that user to access system-level privileges. In addition, it is highly recommend that some form of multi-factor authentication is used for any privileged accounts`}</p>
      </li>
    </ol>
    <h3>{`Password Change`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Passwords should be changed only when there is reason to believe a password has been compromised.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Password cracking or guessing may be performed on a periodic or random basis by the Infosec Team or its delegates. If a password is guessed or cracked during one of these scans, the user will be required to change it to be in compliance with the Password Construction Guidelines.`}</p>
      </li>
    </ol>
    <h3>{`Password Protection`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Passwords must not be shared with anyone, including supervisors and coworkers. All passwords are to be treated as sensitive, Confidential Data Migrators information. Corporate Information Security recognizes that legacy applications do not support proxy systems in place. Please refer to the technical reference for additional details. `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Passwords must not be inserted into email messages, Alliance cases or other forms of electronic communication, nor revealed over the phone to anyone. `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Passwords may be stored only in “password managers” authorized by the organization.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Do not use the “Remember Password” feature of applications (for example, web browsers).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Any user suspecting that his/her password may have been compromised must report the incident and change all passwords.`}</p>
      </li>
    </ol>
    <h3>{`Application Development`}</h3>
    <p>{`Application developers must ensure that their programs contain the
following security precautions:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Applications must support authentication of individual users, not
groups.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Applications must not store passwords in clear text or in any easily
reversible form.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Applications must not transmit passwords in clear text over the
network.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Applications must provide for some sort of role management, such
that one user can take over the functions of another without having
to know the other’s password.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Multi-Factor Authentication is highly encouraged and should be used
whenever possible, not only for work related accounts but personal accounts also.`}</p>
      </li>
    </ol>
    <h2>{`Policy Compliance`}</h2>
    <h3>{`Compliance Measurement`}</h3>
    <p>{`The Infosec team will verify compliance to this policy through various
methods, including but not limited to, periodic walk-thrus, video
monitoring, business tool reports, internal and external audits, and
feedback to the policy owner.`}</p>
    <h2>{`Exceptions`}</h2>
    <p>{`Any exception to the policy must be approved by the Infosec Team in
advance.`}</p>
    <h2>{`Non-Compliance`}</h2>
    <p>{`An employee found to have violated this policy may be subject to
disciplinary action, up to and including termination of employment.`}</p>
    <h2>{`Related Documents`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "password_construction_guidelines"
        }}>{`Password Construction Guidelines`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      